import { defineStore } from 'pinia';

import { storeToRefs } from 'pinia';
import { useLoganStore } from './logan';
import {
	useAddProductsToWishlist,
	useUserWishlists,
	type Wishlist,
} from '~/data/wishlist';

export const useWishlistStore = defineStore('wishlist', () => {
	const { loganId } = storeToRefs(useLoganStore());
	const favouriteProductsQueue = ref<number[]>([]);

	const { userWishlists, areWishlistsLoading } = useUserWishlists(loganId);
	const { addProductsToWishlist } = useAddProductsToWishlist();

	const wishlists = computed(() => {
		if (!userWishlists.value) {
			return null;
		}
		let favouriteList: Wishlist | null = null;
		const otherLists: Wishlist[] = [];
		for (const list of userWishlists.value) {
			if (list.name !== 'Favourites') {
				otherLists.push(list);
			} else {
				favouriteList = list;
			}
		}
		if (!favouriteList) {
			return null;
		}
		return {
			favouriteList,
			otherLists,
		};
	});

	watch(
		wishlists,
		(lists) => {
			if (lists && favouriteProductsQueue.value.length > 0) {
				const favListId = lists.favouriteList.id;
				addProductsToWishlist({
					id: favListId,
					products: favouriteProductsQueue.value,
				});
				favouriteProductsQueue.value = [];
			}
		},
		{ immediate: true },
	);

	const favouritesMap = computed(() => {
		const set = new Set<number>();
		if (!wishlists.value?.favouriteList) {
			return set;
		}
		const productIds = wishlists.value.favouriteList.products;
		for (const productId of productIds) {
			set.add(productId);
		}
		return set;
	});

	return {
		favouriteProductsQueue,
		favouritesMap,
		wishlists,
		areWishlistsLoading,
		addProductsToWishlist,
	};
});

export const useWishlistTestStore = defineStore('wishlist', () => {
	const favouriteProductsQueue = ref<number[]>([]);

	const wishlists = computed(() => {
		return null;
	});

	const favouritesMap = computed(() => {
		const set = new Set<string>();
		return set;
	});

	// handle delayed adding of products to wishlist
	watch(wishlists, (lists) => {
		if (lists) {
			favouriteProductsQueue.value = [];
		}
	});

	return {
		wishlists,
		areWishlistsLoading: ref(false),
		favouritesMap,
		favouriteProductsQueue,
	};
});
